<template>
  <div>
    <b-input-group>
      <b-form-input
        v-model="formattedtimeval"
        type="text"
        :formatter="timeFormatter"
        lazy-formatter
        @blur="parseTime"
        :readonly="disableTime"
        placeholder="HH:mm">
      </b-form-input>
      <b-input-group-append>
        <b-form-timepicker :id="`TIME_${id}`" v-model="timeval" locale="en"
          hide-header 
          button-only
          :show-seconds="false"
          reset-button
          reset-button-variant="danger" 
          close-button-variant="secondary"
          :label-reset-button="$t('time.reset')"
          :disabled="disableTime"
          :readonly="readOnly"
          @context="onContext($event)"
          :minutes-step="disableMinutes ? 60 : 1"
        >
          <template v-slot:button-content="{ }">
            <font-awesome-icon :icon="['far', 'clock']" />
          </template>
        </b-form-timepicker>
      </b-input-group-append>
    </b-input-group>
    <b-popover
      :target="`TIME_${id}`"
      placement="top"
      triggers="hover"
      :content="$t('button.time')">
    </b-popover>
  </div>
</template>


<script>
import { cloneDeep } from 'lodash';
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { strRandom } from '@/helpers';

export default {
  name: 'Timepicker',
  components: {

  },
  props: {
    value: { type: String, default: null },
    disableTime: { type: Boolean, default: false },
    disableMinutes: { type: Boolean, default: false },
    readOnly: { type: Boolean, default: false }
  },
  data() {
    return {
      id: strRandom(5),
      timeval: null,
      formattedtimeval: null
    }
  },
  watch: {
    timeval(newValue) {
      if (this.disableMinutes) {
        const matches = /^(\d+):(\d+).*?$/g.exec(newValue);
        if (matches !== null && matches[2] !== '00') {
          newValue = `${matches[1]}:00:00`;
        }
      }
      
      if (this.value !== newValue) {
        if (newValue === '') {
          this.formattedtimeval = newValue;
        }
        else if (newValue) {
          this.formattedtimeval = this.timeFormatter(newValue);
        }
        this.$emit('input', newValue === ''? null : newValue);
      }
    },
    value(newValue) {
      this.timeval = cloneDeep(newValue);
      this.formattedtimeval = this.timeFormatter(newValue);
    }
  },
  mounted() {
    if (this.value !== null) {
      this.timeval = cloneDeep(this.value);
      this.formattedtimeval = this.timeFormatter(this.value);
    }
    else {
      this.timeval = null;
    }
  },
  computed: {

  },
  methods: {
    timeFormatter(value) {
      if (value === null ||
          value === '') {
        return '';
      }
      
      const retval = moment(value, ['h:m a', 'H:m']).format("hh:mm A");
      return retval !== 'Invalid date' ? retval : this.$t('invalid_time');
    },
    parseTime(/*val*/) {
      if (this.formattedtimeval !== null && this.formattedtimeval.trim().length > 0) {
        this.$set(this, 'timeval', moment(this.formattedtimeval, ['h:m a', 'H:m']).format('HH:mm'));
      } else {
        this.$set(this, 'timeval', null);
      }
    },
    onContext(event) {
      this.$emit('context', event);
    }
  }
}
</script>
