var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        [
          _c("b-form-input", {
            attrs: {
              type: "text",
              formatter: _vm.timeFormatter,
              "lazy-formatter": "",
              readonly: _vm.disableTime,
              placeholder: "HH:mm",
            },
            on: { blur: _vm.parseTime },
            model: {
              value: _vm.formattedtimeval,
              callback: function ($$v) {
                _vm.formattedtimeval = $$v
              },
              expression: "formattedtimeval",
            },
          }),
          _c(
            "b-input-group-append",
            [
              _c("b-form-timepicker", {
                attrs: {
                  id: "TIME_" + _vm.id,
                  locale: "en",
                  "hide-header": "",
                  "button-only": "",
                  "show-seconds": false,
                  "reset-button": "",
                  "reset-button-variant": "danger",
                  "close-button-variant": "secondary",
                  "label-reset-button": _vm.$t("time.reset"),
                  disabled: _vm.disableTime,
                  readonly: _vm.readOnly,
                },
                on: {
                  context: function ($event) {
                    return _vm.onContext($event)
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "button-content",
                    fn: function (ref) {
                      return [
                        _c("font-awesome-icon", {
                          attrs: { icon: ["far", "clock"] },
                        }),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.timeval,
                  callback: function ($$v) {
                    _vm.timeval = $$v
                  },
                  expression: "timeval",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("b-popover", {
        attrs: {
          target: "TIME_" + _vm.id,
          placement: "top",
          triggers: "hover",
          content: _vm.$t("button.time"),
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }