import { get } from 'lodash';

export function tooltipRenderer(params) {
  const value = params.datum[params.yKey];
  const formatValue = value == null || isNaN(value)? '0': parseFloat(value).toFixed(0).toString()
  return {
    title: params.datum?.name || '',
    content: params.yName + ": " + formatValue,
  };
}


export function tooltipRendererGenerator({ title=null, contentLabel=null, contentValue=null, staticTitle=null, staticContentLabel=null, contentValueFormatter=null } = {}) {
  return params => {
    const value = contentValue == null? params.yKey : get(params, contentValue, null);
    let formatValue = null;
    if (contentValueFormatter != null && typeof contentValueFormatter === 'function') {
      formatValue = contentValueFormatter(value);
    } else {
      formatValue = value == null || isNaN(value)? '0': parseFloat(value).toFixed(0).toString();
    }
    const t = staticTitle != null? staticTitle : (title == null? params.xValue : get(params, title, null));
    const cLabel = staticContentLabel != null? staticContentLabel : (contentLabel == null? params.yName : get(params, contentLabel, null));
    return {
      title: t,
      content: `${cLabel}: ${formatValue}`
    };
  }
}

export function customTooltipRenderer(params) {
  const value = params.datum[params.yKey];
  const formatValue = value == null || isNaN(value)? '0': parseFloat(value).toFixed(0).toString()
  return (
    '<div class="ag-chart-tooltip-title" style="background-color:' +
    params.color +
    '">' +
    params.datum[params.xKey] +
    '</div>' +
    '<div class="ag-chart-tooltip-content">' +
    params.yName + ': ' + formatValue +
    '</div>'
  );
}

export function genericChartTooltipRendererGenerator({ type=null, contentValueFormatter=null } = {}) {
  return params => {
    const key = type == 'pie' ? 'angleKey' : type == 'histogram' ? 'xKey' : 'yKey';
    const value = params.datum[params[key]];
    let formatValue = null;
    if (contentValueFormatter != null && typeof contentValueFormatter === 'function') {
      formatValue = contentValueFormatter(params[key].replaceAll('|', '.'), value);
    } else {
      formatValue = value == null || isNaN(value)? '0': parseFloat(value).toFixed(0).toString();
    }

    let title = null;
    let contentLabel = null;
    if (type == 'pie') {
      title = params.calloutLabelName != null? params.calloutLabelName : params.calloutLabelKey != null? params.datum[params.calloutLabelKey] : null;
      contentLabel = params.angleName != null? params.angleName : null;
    } else {
      title = params.xKey != null? params.datum[params.xKey] : null;
      contentLabel = params.yName != null? params.yName : null;
    }

    //Different display for line and scatter
    if (type == 'line' || type == 'scatter') {
      title = params.title;
      if (params.datum[params.xKey]) {
        title += ': ' + params.datum[params.xKey];
      }
      contentLabel = null; //reset to null
    }

    //default
    return (
      (title != null? `<div class="ag-chart-tooltip-title" style="background-color:${params.color}">${title}</div>`:'') +
      '<div class="ag-chart-tooltip-content">' +
      (contentLabel != null? contentLabel + ': ' : '') + formatValue +
      '</div>'
    );
  }
}